/* eslint-disable no-shadow */
/**
 * Saves information about currently logged in user to state
 */
import { createReducer } from "@reduxjs/toolkit";
import merge from "lodash/merge";
import { AnyObject, LingoError, User } from "@thenounproject/lingo-core";
import { fetchCurrentUser } from "@queries/useCurrentUser";
import { updateUserAvatar } from "@redux/actions/users/useUpdateUserAvatar";
import { updateUserProfile } from "@redux/actions/users/useUpdateUserProfile";
import { login } from "@redux/actions/auth/useLogin";
import { signup } from "@redux/actions/auth/useSignup";
import { saveNotificationSeen } from "@redux/actions/useSaveNotificationSeen";
import { logout } from "@redux/actions/auth/useLogout";
import { verifyTOTP } from "@redux/actions/auth/useVerifyTOTP";
import { deleteTOTP } from "@redux/actions/auth/useDeleteTOTP";
import { loginWithGoogle } from "@redux/actions/auth/useLoginWithGoogleToken";

const _seenNotifications = JSON.parse(localStorage.seenNotifications ?? "{}");

export type UserState = Partial<User> & {
  seenNotifications?: AnyObject;
  error?: LingoError;
  isFetched?: boolean;
  isFetching?: boolean;
  // This is mainly used to detmined auth state
  // When using magic token fetches for email prefs.
  isAuthenticated?: boolean;
};

const initialState = {
  isFetching: false,
  isFetched: false,
  isAuthenticated: false,
  error: null,
  id: null,
  isActive: true,
  avatar: null,
  name: null,
  email: null,
  seenNotifications: _seenNotifications,
  mfa: {},
};
export default createReducer<UserState>(initialState, builder => {
  builder
    .addCase(logout.pending, () => {
      return initialState;
    })
    .addCase(fetchCurrentUser.pending, state => {
      state.isFetching = true;
    })
    .addCase(fetchCurrentUser.rejected, (state, action) => {
      state.isFetching = false;
      state.error = action.payload;
    })
    .addCase(fetchCurrentUser.fulfilled, (state, action) => {
      const user = action.payload.entities.users[action.payload.result.user];
      merge(state, user, {
        error: null,
        isFetched: true,
        isFetching: false,
        isAuthenticated: true,
      });
    })
    .addCase(updateUserProfile.fulfilled, (state, action) => {
      const userData = action.payload.entities.users[action.payload.result.user];
      return { ...state, ...userData, isFetched: true, isFetching: false };
    })
    .addCase(updateUserAvatar.fulfilled, (state, action) => {
      const userData = action.payload.entities.users[action.payload.result.user];
      return { ...state, ...userData, isFetched: true, isFetching: false };
    })
    .addCase(login.fulfilled, (state, action) => {
      const userData = action.payload.entities.users[action.payload.result.user];
      return { ...state, ...userData };
    })
    .addCase(loginWithGoogle.fulfilled, (state, action) => {
      const userData = action.payload.entities.users[action.payload.result.user];
      return { ...state, ...userData };
    })
    .addCase(signup.fulfilled, (state, action) => {
      const userData = action.payload.entities.users[action.payload.result.user];
      return { ...state, ...userData };
    })
    .addCase(saveNotificationSeen, (state, action) => {
      const { spaceId, notificationId, seen } = action.payload;
      if (spaceId) {
        merge(state.seenNotifications, {
          [spaceId]: { [notificationId]: seen },
        });
      } else {
        merge(state.seenNotifications, { [notificationId]: seen });
      }
      localStorage.seenNotifications = JSON.stringify(state.seenNotifications);
    })
    .addCase(verifyTOTP.fulfilled, state => {
      state.mfa = { totp_enabled: true };
    })
    .addCase(deleteTOTP.fulfilled, state => {
      state.mfa = { totp_enabled: false };
    });
});
