import React, { useCallback, useEffect } from "react";
import {
  Button,
  useNavigation,
  ActivityIndicator,
  Notice,
  OptionCard,
  Flex,
  ErrorCode,
  buildURL,
} from "@thenounproject/lingo-core";
import { AuthContainer, AuthContent, AuthFormWrapper } from "./AuthElements";
import AuthFormTitle from "./AuthFormTitle";
import OrSeparator from "./OrSeparator";
import useJoinableSpaces from "@redux/actions/auth/useJoinableSpaces";
import ConfirmIdentity from "./ConfirmIdentity";
import SpacePreviewTitle from "./SpacePreviewTitle";
import useJoinSpace from "@redux/actions/spaces/useJoinSpace";
import useCurrentUser from "@queries/useCurrentUser";

const JoinSpaces: React.FC = () => {
  const { data: joinableSpaces, isLoading, error: fetchingError } = useJoinableSpaces();
  const [selectedSpaceId, setSelectedSpaceId] = React.useState<number>();
  const selectedSpace = selectedSpaceId && joinableSpaces.find(s => s.id === selectedSpaceId);
  const { user } = useCurrentUser();

  const [_joinSpace, { isProcessing, error }] = useJoinSpace();
  const navigation = useNavigation();

  const joinSpace = useCallback(async () => {
    const res = await _joinSpace({ spaceId: selectedSpaceId });
    if (res.isSuccess) {
      const { entities, result } = res.response,
        space = entities.spaces[result];
      const url = buildURL("/", { space });
      navigation.push(url);
    }
  }, [_joinSpace, navigation, selectedSpaceId]);

  useEffect(() => {
    if (fetchingError?.code === ErrorCode.unauthorized) {
      navigation.push("/login?next=/join-spaces");
    }
  }, [fetchingError?.code, navigation]);

  const confirmHeader = (
    <SpacePreviewTitle titlePrefix={`Confirm identity to join`} space={selectedSpace} />
  );

  return (
    <AuthContainer>
      <AuthContent withHeader>
        <AuthFormWrapper>
          {isLoading && <ActivityIndicator center />}
          {!isLoading && !error && !selectedSpaceId && (
            <>
              <AuthFormTitle
                title="Join your team"
                message={
                  joinableSpaces?.length > 1
                    ? `It looks like your team has more than one workspace on Lingo, pick one to join and get started.`
                    : "Select a space to join and get started"
                }
              />
              <Flex flexDirection="column">
                <Flex flexDirection="column" gap="16px" mb="l">
                  {joinableSpaces?.map(s => (
                    <OptionCard
                      key={s.id}
                      title={s.name}
                      cardStyle="disclosure"
                      image={{ src: s.avatar, style: "circle" }}
                      onClick={() => setSelectedSpaceId(s.id)}
                    />
                  ))}
                </Flex>
                <OrSeparator />
                <Button
                  mt="l"
                  text="Not your team? Create a new space"
                  buttonStyle="primary"
                  type="button"
                  width="100%"
                  link={`/space/new`}
                />
              </Flex>
            </>
          )}

          {!isLoading && !error && selectedSpaceId && (
            <>
              <ConfirmIdentity
                header={confirmHeader}
                user={user}
                buttonText="Join"
                onConfirmSelected={joinSpace}
                disabled={isProcessing}
                onGoBack={() => setSelectedSpaceId(undefined)}
              />
            </>
          )}
          {error && <Notice message={error.message} noticeStyle="error" />}
          {fetchingError && <Notice message={fetchingError.message} noticeStyle="error" />}
        </AuthFormWrapper>
      </AuthContent>
    </AuthContainer>
  );
};

export default React.memo(JoinSpaces) as typeof JoinSpaces;
