import React, { useState, useEffect, useCallback, FormEvent } from "react";
import { Button, Input, Kit, Portal, SpacePreview } from "@thenounproject/lingo-core";

import { AuthFormWrapper, authButtonStyle, AuthFormMessage } from "./AuthElements";

import useEnterPassword from "@redux/actions/auth/useEnterPassword";
import AuthFormTitle from "./AuthFormTitle";
import useAuthHandler from "./useAuthHandler";

type Props = {
  portalId?: Portal["id"];
  kitId?: Kit["kitId"];
  spaceIdentifier: string | number;
  space?: SpacePreview;
  portal?: Portal;
  autoPassword?: string;
  responseHandler?: ReturnType<typeof useAuthHandler>;
};

function PasswordEntry({
  kitId,
  portalId,
  spaceIdentifier,
  space,
  portal,
  autoPassword,
  responseHandler,
}: Props) {
  const [password, setPassword] = useState(autoPassword || ""),
    [enterPassword] = useEnterPassword(),
    submitPassword = useCallback(
      (pass: string) => {
        const act = enterPassword({
          password: pass,
          spaceId: spaceIdentifier,
          kitId,
          portalId,
        });
        void responseHandler.process(async () => await act);
      },
      [enterPassword, spaceIdentifier, kitId, portalId, responseHandler]
    ),
    onPasswordSubmit = useCallback(
      (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        submitPassword(password);
      },
      [submitPassword, password]
    );

  /**
   * Mount effect
   */
  useEffect(() => {
    if (autoPassword) {
      setPassword(autoPassword);
      submitPassword(autoPassword);
    }
  }, [autoPassword, submitPassword]);

  const { isProcessing, error } = responseHandler;

  return (
    <AuthFormWrapper>
      <AuthFormTitle title="Password required" space={space} portal={portal} />

      <form data-testid="password-entry-form" onSubmit={onPasswordSubmit}>
        <Input
          id="password"
          onChange={e => {
            setPassword(e.target.value);
          }}
          value={password}
          type="password"
          label="Password"
          styleOverrides={{ pb: "m" }}
          message={error?.message}
          inputStyle={error ? "error" : null}
        />
        <Button
          id="submit-button"
          {...authButtonStyle}
          type="submit"
          text="Enter"
          disabled={isProcessing}
        />
        <AuthFormMessage>
          <span>
            Already a member of this space?{" "}
            <Button
              id="login-button"
              text="Login instead"
              buttonStyle="tertiary"
              size="small"
              link={`/login/${window.location.search || ""}`}
            />
          </span>
        </AuthFormMessage>
        <AuthFormMessage>
          <Button
            text="Login with SSO"
            buttonStyle="tertiary"
            size="small"
            link={`/login/sso/${window.location.search || ""}`}
          />
        </AuthFormMessage>
      </form>
    </AuthFormWrapper>
  );
}

export default PasswordEntry;
