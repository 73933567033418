import QueryString from "query-string";
import { match as matchUrl } from "path-to-regexp";
import { useMemo } from "react";
import {
  getCustomDomainComponents,
  ErrorCode,
  SpacePreview,
  Portal,
} from "@thenounproject/lingo-core";
import { parseHostname } from "shared";
import { useLocation } from "react-router-dom";

import useSpacePreview from "@redux/actions/spaces/useSpacePreview";
import useIdentifyDomain from "@redux/actions/spaces/useIdentifyDomain";

type Result = {
  tokenError?: number;
  nextSpaceIdentifier?: string | number;
  token?: AuthToken;
  portalId?: string;
  kitId?: string;
  autoPassword?: string;
  redirectUrl: string;
  authType: AuthType;
  signupStep?: SignupStep;
  spacePreview?: SpacePreview;
  portalPreview?: Portal;
  previewIsLoading: boolean;
};

export enum AuthType {
  login = "login",
  signup = "signup",
  enterPassword = "enter-password",
  enterEmail = "enter-email",
  joinSpace = "join-space",
  sso = "sso",
  linkAccount = "link-account",
  join = "join",
  invitation = "invitation",
  passwordReset = "password-reset",
}

export enum SignupStep {
  verificationSent = "verification-sent",
}

type AuthTokenType =
  | "magic-login"
  | "join-link"
  | "join-email"
  | "invitation"
  | "link-google"
  | "link-sso"
  | "password-reset";

const TOKEN_GENERATED_PREVIEWS: AuthTokenType[] = [
  "invitation",
  "link-sso",
  "link-google",
  "join-link",
];

const KNOWN_TOKEN_ERRORS = [
  ErrorCode.invitationAlreadyAccepted,
  ErrorCode.invitationNotFound,
  ErrorCode.joinLinkNotValid,
] as number[];

export type AuthToken = {
  token: string;
  type: AuthTokenType;
};

type Props = {
  authType: AuthType;
  signupStep?: SignupStep;
};

// Determines the auth state based on the URL
export default function useAuthState({ authType, signupStep }: Props): Result {
  // Get variables from the url

  const location = useLocation(),
    query = QueryString.parse(location.search),
    redirectTo = query.next as string,
    kitId = query.kitId as string,
    portalId = query.portalId as string;

  const { identifier } = getCustomDomainComponents(),
    components = getURLComponents(redirectTo),
    autoPassword = components.autoPassword,
    redirectUrl = components.cleanedUrl,
    token = useMemo(() => {
      // If we are are followiing a magic login link, that takes precedence
      const currentUrlToken = parseUrl(location.pathname);
      if (currentUrlToken?.type === "magic-login") {
        return currentUrlToken;
      }

      let fromUrl = components.cleanedUrl;
      if (fromUrl && !fromUrl.startsWith("/")) {
        try {
          fromUrl = new URL(fromUrl).pathname;
        } catch {
          // that's ok
        }
      }
      return parseUrl(fromUrl) || parseUrl(location.pathname);
    }, [components.cleanedUrl, location.pathname]);

  const nextSpaceIdentifier = useMemo(() => {
    if (TOKEN_GENERATED_PREVIEWS.includes(token?.type) && authType !== AuthType.login)
      return token.token;
    if (components.spaceId) return components.spaceId;
    if (authType !== AuthType.passwordReset) {
      return token?.token;
    }
    return undefined;
  }, [authType, components.spaceId, token]);

  // Fetching the space/portal previews
  const spacePreviewResult = useSpacePreview(
    {
      spaceId: nextSpaceIdentifier,
    },
    { skip: !nextSpaceIdentifier }
  );

  const domainIdentificationResult = useIdentifyDomain(
    {
      identifier: identifier ?? components.domainIdentifier,
    },
    { skip: !(identifier ?? components.domainIdentifier) }
  );

  const spacePreview = spacePreviewResult.data ?? domainIdentificationResult.data?.space,
    portalPreview = domainIdentificationResult.data?.portal,
    error = spacePreviewResult.error ?? domainIdentificationResult.error,
    isLoading = spacePreviewResult.isLoading || domainIdentificationResult.isLoading;

  const tokenError = useMemo(() => {
    if (KNOWN_TOKEN_ERRORS.includes(error?.code)) {
      return error?.code;
    }
    if ("join-link" === token?.type && error?.code === ErrorCode.spaceNotFound) {
      return error?.code;
    }
  }, [error?.code, token]);

  return {
    authType,
    signupStep,
    token,
    tokenError,
    kitId,
    portalId,
    nextSpaceIdentifier:
      nextSpaceIdentifier ??
      domainIdentificationResult?.data?.spaceId ??
      spacePreview?.id ??
      portalPreview?.spaceId,
    autoPassword,
    redirectUrl,
    spacePreview,
    portalPreview,
    previewIsLoading: (identifier ?? nextSpaceIdentifier) && isLoading,
  };
}

function parseUrl(url: string): AuthToken {
  if (url) {
    const next = url.split("?")[0].split("/");
    if (next[1] === "login" && next[2] && !["sso", "mac"].includes(next[2]))
      return { token: next[2], type: "magic-login" };
    if (next[1] === "join") return { token: next[3], type: "join-link" };
    if (next[1] === "invitation") return { token: next[2], type: "invitation" };
    if (next[1] === "link-account" && next[2] === "google")
      return { token: next[3], type: "link-google" };
    if (next[1] === "link-account") return { token: next[2], type: "link-sso" };
    if (next[1] === "password-reset" && next[2]) {
      return { token: next[2], type: "password-reset" };
    }
  }
}

function getURLComponents(fromUrl: string): {
  spaceId?: number;
  domainIdentifier?: string;
  autoPassword?: string;
  cleanedUrl?: string;
} {
  if (!fromUrl) return {};
  const redirectComponents = QueryString.parseUrl(decodeURIComponent(fromUrl)),
    { kit_token, tkn, password, ...otherQuery } = redirectComponents.query,
    redirectQuery = QueryString.stringify(otherQuery),
    matched = matchUrl("/:spaceId(\\d+)", { end: false, start: false })(
      redirectComponents.url
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ) as any,
    _spaceId = parseInt(matched?.params?.spaceId),
    spaceId = Number.isNaN(_spaceId) ? null : _spaceId;

  let cleanedUrl = redirectComponents.url;
  if (redirectQuery) cleanedUrl += `?${redirectQuery}`;
  return {
    spaceId: spaceId,
    domainIdentifier: parseHostname(redirectComponents.url).identifier,
    autoPassword: (kit_token || tkn || password) as string,
    cleanedUrl,
  };
}
