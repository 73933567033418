import React from "react";
import Helmet from "react-helmet";
import { Box, Text, SpacePreview, ImageView, Portal } from "@thenounproject/lingo-core";

import useCustomFavicon from "../useCustomFavicon";

type Props = {
  title: string;
  message?: string;
  space?: SpacePreview;
  portal?: Portal;
  alwaysShowAvatar?: boolean;
  extra?: React.ReactNode;
};

const IMAGE_SIZE = 140;

const AuthFormTitle: React.FC<Props> = ({
  title,
  message,
  space,
  portal,
  alwaysShowAvatar,
  extra,
}) => {
  useCustomFavicon(space, portal);

  const themeActive = space?.theme?.active;
  const showAvatar = themeActive || alwaysShowAvatar;

  let avatar: string;
  if (portal) {
    avatar = portal.images?.avatars.avatar ?? portal.images?.avatars?.defaultAvatar;
  } else if (space) {
    avatar = space.avatars?.avatar ?? space.avatars?.defaultAvatar;
  }

  return (
    <Box width="100%" textAlign="center" mb={showAvatar ? "l" : "xxl"}>
      <Helmet title={title} />
      {showAvatar && (
        <Box
          data-testid="auth-branding-avatar"
          width={IMAGE_SIZE}
          height={IMAGE_SIZE}
          m="auto"
          mb="l">
          <ImageView
            overflow="hidden"
            borderRadius="50%"
            src={avatar}
            cover
            width={IMAGE_SIZE}
            height={IMAGE_SIZE}
          />
        </Box>
      )}
      <Text as="h1" font={showAvatar ? "ui.title" : "marketing.h2"}>
        {title}
      </Text>
      {message && (
        <Text color="grayDarkest" font={showAvatar ? "ui.regular" : "marketing.bodyLarge"}>
          {message}
        </Text>
      )}
      {extra}
    </Box>
  );
};

export default AuthFormTitle;
